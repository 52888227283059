import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Section } from "../Section"
import PreviewVideoMP4 from "../../images/livecycle-demo.mp4"

export const Video: React.FC = () => {
  return (
    <Section
      className="w-screen max-w-none bg-cover	bg-center bg-no-repeat md:bg-contain relative top-0 bg-lc-movie-black"
      limitMaxWidth={false}
      padding="px-0"
    >
      <div
        className="relative h-screen-105 sm:h-video-section flex lc-border"
        style={{ borderLeft: "none", borderRight: "none" }}
      >
        <StaticImage
          layout="fixed"
          formats={["webp"]}
          quality={100}
          placeholder="blurred"
          // height={1013}
          alt="background"
          src="../../images/homepage-raw-assets/bg_video@2x.jpg"
          backgroundColor="#1d1d1d"
          objectFit="contain"
          objectPosition="bottom"
          style={{ height: "100%" }}
        />
      </div>
      <video
        className="absolute top-0 lc-border sm:hidden"
        autoPlay
        // style={{ left: "80px", width: "1085px", borderTop: "none" }}
        loop
        muted
        playsInline
        crossOrigin="anonymous"
      >
        <source src={PreviewVideoMP4} type="video/mp4" />
      </video>
    </Section>
  )
}
