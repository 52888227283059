import React from "react"
import { Layout } from "../components"
import { IntercomProvider } from "react-use-intercom"
import { graphql, PageProps } from "gatsby"
import {
  Title,
  Integrations,
  UseCases,
  Focus,
  PainPoints,
} from "../components/sections"
import { Video } from "../components/sections/Video"
import { ConnectToProject } from "../components/sections/ConnectToProject"
import { ChakraProvider } from "@chakra-ui/react"
import { chakraTheme } from "../components/chakraTheme"

interface Props {
  data: {
    file: {
      name: string
    }
  }
}

const IndexPage: React.FC<PageProps & Props> = ({ data }) => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <IntercomProvider appId={process.env.GATSBY_INTERCOM_APP_ID} autoBoot>
        <Layout navigationBarVariant="grey">
          <Title />
          <Video />
          <PainPoints />
          <ConnectToProject />
          <Integrations />
          <UseCases />
          <Focus />
        </Layout>
      </IntercomProvider>
    </ChakraProvider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    filename: file(relativePath: { eq: "images/livecycle-io-playground.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
