import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Section } from "../Section"
import { ScaleAndStretch } from "./ScaleAndStretch"

export const UseCases = () => {
  return (
    <Section className="mt-26 sm:mt-56" padding="p-0">
      <ScaleAndStretch
        maxWidth={1280}
        className="h-use-cases-section-mobile sm:h-use-cases-section"
      >
        <h1 className="mb-26 sm:mb-56 text-4.5xl sm:text-8xl">
          Welcome to <br className="sm:hidden" />
          the land of <br className="sm:hidden" />
          headache-free <br className="sm:hidden" />
          product <br className="sm:hidden" />
          development.
        </h1>
        <div className="relative">
          <svg
            width="25"
            height="423"
            viewBox="0 0 25 423"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sm:hidden absolute"
            style={{ right: "100px", bottom: "-20.8rem", zIndex: 1 }}
          >
            <path
              d="M17.7633 1.00066L22.6094 132.066L4.84876 132.723L17.7633 1.00066Z"
              fill="white"
              stroke="black"
              strokeWidth="3"
            />
            <rect
              x="11"
              y="336"
              width="8"
              height="85"
              fill="white"
              stroke="black"
              strokeWidth="3"
            />
            <rect
              x="11"
              y="201"
              width="8"
              height="85"
              fill="white"
              stroke="black"
              strokeWidth="3"
            />
          </svg>

          {/* First Bubble */}
          <div className="absolute right-28 -bottom-20 sm:right-38 sm:-top-30 z-10">
            <div
              style={{ lineHeight: "1.1em" }}
              className="absolute p-4 bg-white -top-10 -left-28 z-10 rounded-2xl border-2 border-black text-2xl sm:text-3xl text-left"
            >
              Instant demo environments
            </div>
            <div className="absolute">
              <svg
                width="43"
                height="204"
                viewBox="0 0 43 204"
                fill="none"
                className="hidden sm:block"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3639 203.173L1.73828 6.28564L40.479 2.42399L21.3639 203.173Z"
                  fill="white"
                  stroke="black"
                  strokeWidth="3"
                />
              </svg>
            </div>
          </div>
          {/* Second Bubble */}
          <div className="absolute right-40 sm:right-156 top-92 sm:top-auto sm:bottom-20 z-10">
            <div
              style={{ lineHeight: "1.1em" }}
              className="absolute p-4 top-36 bg-white -right-28 z-10 rounded-2xl border-2 border-black text-2xl sm:text-3xl text-left w-64 sm:w-76"
            >
              Async product and design review
            </div>
            <div className="absolute">
              <svg
                width="38"
                height="190"
                viewBox="0 0 38 190"
                fill="none"
                className="hidden sm:block"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.1488 0.89077L35.5977 188L2.21271 184.979L23.1488 0.89077Z"
                  fill="white"
                  stroke="black"
                  strokeWidth="3"
                />
              </svg>
            </div>
          </div>
          {/* Third Bubble */}
          <div className="absolute right-16 sm:right-72 bottom-4 sm:bottom-60 z-10">
            <div
              style={{ lineHeight: "1.1em" }}
              className="absolute p-4 top-76 bg-white -left-48 z-10 rounded-2xl border-2 border-black text-2xl sm:text-3xl text-left w-56 sm:w-100"
            >
              Contextual collaboration for remote teams
            </div>
            <div className="absolute">
              <svg
                width="39"
                height="354"
                viewBox="0 0 39 354"
                fill="none"
                className="hidden sm:block"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.11303 0.547558L36.9727 352L3.5073 350.063L2.11303 0.547558Z"
                  fill="white"
                  stroke="black"
                  strokeWidth="3"
                />
              </svg>
            </div>
          </div>
          <div className="h-88 sm:h-auto p-0 sm:p-8">
            <div className="hidden sm:block">
              <StaticImage
                alt="Background"
                src="../../images/homepage-raw-assets/lawn@2x.png"
                className="lc-border "
                width={1280}
                layout="constrained"
                objectFit="scale-down"
              ></StaticImage>
            </div>
            <div className="sm:hidden h-full">
              <StaticImage
                alt="Background"
                src="../../images/homepage-raw-assets/lawn@2x.png"
                className="lc-border h-full"
                width={1280}
                layout="constrained"
                objectFit="cover"
                imgStyle={{ height: "100%" }}
              ></StaticImage>
            </div>
          </div>
        </div>
      </ScaleAndStretch>
    </Section>
  )
}
