import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { CTALink } from "../CTALink"
import { ScaleAndStretch } from "./ScaleAndStretch"
import { Section } from "../Section"
import { WithClassName } from "../types"

const TopLine: React.FC<WithClassName> = ({ className }) => {
  return (
    <svg
      width="133"
      height="74"
      viewBox="0 0 133 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.932474 0.266859C0.729292 14.5392 117.192 16.2619 132.293 73.0011"
        stroke="#0000FF"
      />
    </svg>
  )
}

const BottomLineSmall: React.FC<WithClassName> = ({ className }) => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.00065 30.2088C26.5002 35.4896 37 13.4896 31.5 0.989641"
        stroke="#0000FF"
      />
    </svg>
  )
}

const BottomLine: React.FC<WithClassName> = ({ className }) => {
  return (
    <svg
      width="71"
      height="45"
      viewBox="0 0 71 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.865977 1.46241C9.2865 -6.37789 33.7819 61.7357 70.4638 39.9985"
        stroke="#0000FF"
      />
    </svg>
  )
}

const TopLineSmall: React.FC<WithClassName> = ({ className }) => {
  return (
    <svg
      width="32"
      height="39"
      viewBox="0 0 32 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M31.0156 0.891205C6.53008 15.2911 38.8912 23.2289 1.00018 38.2839"
        stroke="#0000FF"
      />
    </svg>
  )
}

export const PainPoints: React.FC = () => {
  return (
    <Section className="mt-30 sm:mt-50">
      <ScaleAndStretch maxWidth={1280} className={"sm:h-pain-points-section"}>
        <div className="mb-40 relative">
          <h1 className="text-4.5xl	sm:text-8xl">
            Ease your <br className="sm:hidden" />
            pain.
          </h1>
          <p className="absolute -right-12 -top-8 sm:-top-24 sm:right-96 text-lc-blue text-lg sm:text-2xl w-max">
            development workflow
            <TopLine className="absolute hidden sm:block top-8 -right-32" />
            <TopLineSmall className="absolute sm:hidden top-10 right-3" />
          </p>
          <p className="absolute -bottom-9 -right-7 sm:bottom-auto sm:top-20 sm:-right-16 text-lc-blue text-lg sm:text-2xl w-max">
            points
            <BottomLine className="absolute hidden sm:block top-2 -left-9" />
            <BottomLineSmall className="absolute sm:hidden transform rotate-90 -top-4 -left-10" />
          </p>
        </div>
        <div className="relative sm:mb-4">
          <div className="hidden sm:block">
            <StaticImage
              alt="Pain points comic"
              src="../../images/homepage-raw-assets/comic.png"
              width={1302}
            />
          </div>
          <div className="sm:hidden w-screen">
            <StaticImage
              alt="Pain points comic"
              src="../../images/homepage-raw-assets/comic_mobile.png"
              width={640}
            />
          </div>
          <p className="absolute top-8 left-8 sm:left-auto sm:top-8 sm:right-4 text-left w-screen-82 sm:w-76 text-screen-6.7 sm:text-4xl leading-screen-10 sm:leading-13.5">
            1. <br />
            Automatically build previews from any PR.
          </p>
          <p className="absolute top-screen-138 right-4 sm:right-auto sm:top-120 sm:left-96 text-left w-screen-53 sm:w-52 text-screen-6.7 sm:text-4xl leading-screen-10 sm:leading-13.5">
            2. <br />
            Get everyone’s feedback...
          </p>
          <p className="absolute bottom-screen-215 sm:bottom-8 left-8 sm:left-auto sm:right-12 text-left w-screen-59 sm:w-48 text-screen-6.7 sm:text-4xl leading-screen-10 sm:leading-13.5">
            3. <br />
            ...directly to your Git provider...
          </p>
        </div>

        <div className="flex flex-col justify-center items-center border-solid border-t-0 border-l-4 border-r-4 border-b-4 border-black sm:border-4 w-full p-24 -top-16 bg-white">
          <h2 className="font-normal mt-4 mb-10 text-4.5xl">
            ...with <b style={{ fontSize: "1.25em" }}>ZERO</b> interruptions.
          </h2>
          <p className="mb-8 mx-8 text-3xl">
            Experience <br className="sm:hidden" />
            distraction-free <br className="sm:hidden" />
            product <br className="sm:hidden" />
            development
          </p>
          <div className="flex flex-col sm:flex-row">
            <CTALink
              target="_blank"
              href="https://livecycle.dev"
              variant="solid"
              className="mr-8 mb-4 sm:mb-0"
            >
              Get started
            </CTALink>
            <CTALink
              target="_blank"
              href="https://calendly.com/d/cjk-9bz-6gj/livecycle-demo-session"
              variant="outline"
            >
              Book a demo
            </CTALink>
          </div>
        </div>
      </ScaleAndStretch>
    </Section>
  )
}
