import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { CTALink } from "../CTALink"
import { Section } from "../Section"
import Arrow from "../../images/svg/arrow.svg"
import { ScaleAndStretch } from "./ScaleAndStretch"
import PreviewVideoMP4 from "../../images/livecycle-demo.mp4"

export const Title = () => {
  return (
    <Section
      className="relative bg-lc-grey-light sm:bg-white z-10"
      padding="px-0 sm:px-4"
    >
      <div className="bottom-0 absolute sm:hidden w-screen">
        <StaticImage
          alt="Playground"
          src="../../images/homepage-raw-assets/hero_mobile@2x.png"
          width={640}
          objectFit="scale-down"
        />
      </div>
      <ScaleAndStretch
        maxWidth={1248}
        className="h-280 sm:h-title-section overflow-hidden sm:overflow-visible"
        heightToWidthRatio={0.73317}
      >
        <div className="relative w-screen sm:w-auto flex flex-row justify-left sm:justify-center mb-38">
          <div className="absolute top-68 sm:top-54 -right-28 sm:-left-22 bg-white text-left lc-border rounded-3xl p-6 sm:p-12 w-112 sm:w-welcome-box z-10">
            <div className="w-72 sm:w-auto">
              <h2 className="pb-7 text-2xl sm:text-4.5xl sm:w-auto font-normal sm:leading-13.5">
                Code more, align better and ship faster with collaborative
                deployment previews.
              </h2>
              <div className="flex flex-col sm:flex-row mb-10">
                <div className="relative">
                  <CTALink
                    target="_blank"
                    variant="solid"
                    className="sm:mr-8 sm:w-50 mb-4 sm:mb-0"
                    widthClassName="w-9/12"
                    href="https://livecycle.dev/"
                  >
                    Try it now!
                  </CTALink>
                  <div className="absolute top-0 -right-12 sm:left-24 sm:right-0 sm:top-9 text-lc-blue">
                    <Arrow className="transform -rotate-70 -scale-x-100 sm:transform-none absolute -left-38 top-8 sm:left-0 sm:top-0" />
                    <p className="absolute w-max top-36 right-30 sm:top-9 sm:left-15 sm:right-0">
                      100% free while in beta
                    </p>
                  </div>
                </div>
                <CTALink
                  target="_blank"
                  variant="outline"
                  className="mr-8 sm:w-50"
                  widthClassName="w-9/12"
                  href="https://calendly.com/d/cjk-9bz-6gj/livecycle-demo-session"
                >
                  Book a demo
                </CTALink>
              </div>
            </div>
          </div>
          <div className="bg-white lc-border sm:border-none rounded-3xl sm:rounded p-12 pr-20 sm:p-0 text-left sm:text-center relative -left-6 sm:static">
            <h1 className="mx-auto leading-tight text-5xl sm:text-8xl w-max mb-16 sm:mb-0">
              Get your{" "}
              <span className="sm:text-lc-blue">
                <br className="sm:hidden" />
                ship <br className="sm:hidden" />
                together.
              </span>
            </h1>
          </div>
        </div>
        <div className="relative flex flex-col mt-8 -right-20">
          <div className="hidden sm:block">
            <StaticImage
              className="lc-border hidden sm:block"
              alt="Playground"
              src="../../images/homepage-raw-assets/hero@2x.png"
              width={1075}
              height={629}
            />
          </div>
          <video
            className="lc-border hidden sm:block"
            autoPlay
            style={{ left: "80px", width: "1087px", borderTop: "none" }}
            loop
            muted
            playsInline
            crossOrigin="anonymous"
          >
            <source src={PreviewVideoMP4} type="video/mp4" />
          </video>
        </div>
      </ScaleAndStretch>
    </Section>
  )
}
