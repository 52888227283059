import React from "react"
import { Section } from "../../Section"
import SlackLogo from "../../../images/svg/slack-logo.svg"
import GitLabLogo from "../../../images/svg/gitlab-logo.svg"
import BitBucketLogo from "../../../images/svg/bitbucket-logo.svg"
import JiraLogo from "../../../images/svg/jira-logo.svg"
import { StaticImage } from "gatsby-plugin-image"
import type { WithClassName } from "../../types"
import { ScaleAndStretch } from "../ScaleAndStretch"

const BubbleArrowDown: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  return (
    <svg
      width="93"
      height="120"
      viewBox="0 0 93 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="left-28 top-43.2 sm:left-38 sm:top-28"
      style={{
        position: "absolute",
        clipPath: "polygon(6.08% 15.83%, 103.23% 15.85%, 100% 80%, 0% 100%)",
        // top: "111px",
        // left: "150px",
        zIndex: 11,
      }}
    >
      <path
        d="M2.34372 118.93L58.5312 -43.5557L90.638 -32.4532L46.4909 43.2386L2.34372 118.93Z"
        fill="white"
        stroke="black"
        strokeWidth="3.19445"
      />
    </svg>
  )
}

const BubbleArrowUp: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  return (
    <>
      <svg
        width="24"
        height="78"
        viewBox="0 0 24 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute sm:hidden -top-16 right-28"
        style={{
          clipPath: "polygon(0% 0%, 100% 0%, 100% 82%, 0% 83%)",
        }}
      >
        <path
          d="M15.8008 1.00103L21.6914 74.4199L1.99891 75.9999L15.8008 1.00103Z"
          fill="white"
          stroke="black"
          strokeWidth="3"
        />
      </svg>
      <svg
        width="130"
        height="123"
        viewBox="0 0 130 123"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden sm:block"
        style={{
          position: "absolute",
          clipPath: "polygon(0% 0%, 100% 0%, 100% 96%, 0% 96%)",
          top: "-118px",
          left: "150px",
          zIndex: 11,
        }}
      >
        <path
          d="M1.55995 1.9998L125.434 121.223L86.5 121.223L1.55995 1.9998Z"
          fill="white"
          stroke="black"
          strokeWidth="3.19445"
        />
      </svg>
    </>
  )
}

const IntegrationApp: React.FC<
  WithClassName<{ variant?: "small" | "large" }>
> = ({ children, variant = "large", className = "" }) => {
  const sizeClassName =
    variant === "small"
      ? " p-6/100 w-26 h-26 sm:w-36 sm:h-36 border-3"
      : "w-48 h-48 sm:w-76 sm:h-76 p-10/100 border-7"

  return (
    <div
      className={`rounded-full bg-white ${sizeClassName} border-solid border-black flex flex-col items-center justify-center ${className}`}
    >
      {children}
    </div>
  )
}

export const Integrations = () => {
  return (
    <Section
      className="w-screen max-w-none bg-cover	bg-center bg-no-repeat md:bg-contain relative"
      limitMaxWidth={false}
    >
      <div className="absolute top-0 bottom-0 sm:h-integrations-section flex">
        <StaticImage
          imgStyle={{ height: "100%" }}
          formats={["webp"]}
          quality={100}
          placeholder="blurred"
          alt="background"
          src="../../../images/homepage-raw-assets/bg_integrations3.png"
          backgroundColor="#e2e2e2"
        />
      </div>
      <ScaleAndStretch
        maxWidth={1280}
        className="h-384 sm:h-integrations-section"
      >
        <div className="relative max-w-screen-xl w-full sm:m-auto px-4 flex flex-col justify-center items-center">
          <h1 className="my-30 text-4.5xl sm:text-8xl">
            Compliment <br className="sm:hidden" /> the tools <br />
            you love to use.
          </h1>
          <div className="relative left-0 top-56 flex flex-col sm:flex-row">
            <div className="flex flex-row relative top:12 sm:top-auto">
              <div className="bg-white text-left lc-border rounded-3xl p-6 sm:p-8 origin-top-right absolute w-72 sm:w-188 -top-68 sm:-top-48 left-8 sm:left-auto">
                <h3 className="text-2xl sm:text-3xl">
                  Seamless, secure integration with <b>Github.</b>
                </h3>
                <p>One-time setup using read-only, revocable access.</p>
                <BubbleArrowDown />
              </div>
              <IntegrationApp className="relative left-8 sm:left-auto z-10">
                <StaticImage
                  placeholder="tracedSVG"
                  alt="Github's Octocat"
                  src="../../../images/Octocat.png"
                  width={167}
                />
              </IntegrationApp>
              <IntegrationApp className="relative -left-12 top-28 sm:top:auto">
                <SlackLogo />
              </IntegrationApp>
              <div className="bg-white text-left lc-border rounded-3xl p-6 sm:p-8 origin-top-right absolute w-80 sm:w-188 top-88 left-12 sm:top-116 sm:left-80">
                <h3 className="text-2xl sm:text-3xl">
                  Syncs with your communication channels.
                </h3>
                <p>
                  Notifications keep you up to date with the latest changes.
                </p>
                <BubbleArrowUp />
              </div>
            </div>
            <div className="ml-20 relative -left-10 sm:left-auto top-100 sm:top-auto">
              <p className="p-3 sm:p-6 rounded-3xl bg-white relative top-4 lc-border">
                Soon to be supported:
              </p>
              <div className="flex flex-row justify-evenly items-end z-10 relative top-12 sm:top-auto">
                <IntegrationApp variant="small">
                  <GitLabLogo />
                </IntegrationApp>
                <IntegrationApp className="mx-4 sm:mx-0" variant="small">
                  <BitBucketLogo />
                </IntegrationApp>
                <IntegrationApp variant="small">
                  <JiraLogo />
                </IntegrationApp>
              </div>
            </div>
          </div>
        </div>
      </ScaleAndStretch>
      {/* </div> */}
    </Section>
  )
}
