import React from "react"
import { CTALink } from "../CTALink"
import { ScaleAndStretch } from "./ScaleAndStretch"
import { Section } from "../Section"

export const Focus = () => {
  return (
    <Section className="mt-30">
      <ScaleAndStretch className="sm:h-focus-section" maxWidth={1140}>
        <div
          className="grid"
          style={{
            gridTemplateColumns: "70px 1000px 70px",
            gridTemplateRows: "70px 1000px 70px",
          }}
        >
          <hr
            style={{ gridArea: "2 / 1 / 3 / 2" }}
            className="border-t-2 border-solid border-black m-auto  w-4/6"
          />
          <hr
            style={{ gridArea: "2 / 3 / 3 / 4" }}
            className="border-t-2 border-solid border-black m-auto  w-4/6"
          />
          <hr
            style={{ gridArea: "1 / 2 / 2 / 3" }}
            className="border-l-2 border-solid border-black m-auto  h-4/6"
          />
          <hr
            style={{ gridArea: "3 / 2 / 4 / 3" }}
            className="border-l-2 border-solid border-black m-auto  h-4/6"
          />
          <div
            className="w-full h-full flex flex-col justify-center bg-black rounded-full items-center"
            style={{
              gridArea: "2 / 2 / 3 / 3",
            }}
          >
            <div className="w-80 sm:w-auto h-1/2 flex flex-col justify-end">
              <h1 className="text-white text-5xl sm:text-6.5xl leading-none">
                Code more. <br />
                Communicate better. <br />
                Ship faster.
              </h1>
            </div>
            <div className="h-1/2 flex flex-col justify-start items-center mt-10">
              <h2 className="text-white font-normal text-2xl sm:text-4xl">
                Take Livecycle <br className="sm:hidden" />
                for a spin
              </h2>
              <div className="flex flex-col sm:flex-row mt-20">
                <CTALink
                  target="_blank"
                  href="https://livecycle.dev"
                  variant="solid"
                  className="mb-4 sm:mb-0 sm:mr-4"
                >
                  Get started
                </CTALink>
                <CTALink
                  target="_blank"
                  href="https://calendly.com/d/cjk-9bz-6gj/livecycle-demo-session"
                  variant="outline-white"
                >
                  Book a demo
                </CTALink>
              </div>
            </div>
          </div>
        </div>
      </ScaleAndStretch>
    </Section>
  )
}
