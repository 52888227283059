import React, { useEffect, useMemo, useRef, useState } from "react"
import { WithClassName } from "../types"

export function useObserveContainerWidth() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const callback: ResizeObserverCallback = entries => {
      setContainerWidth(entries[0].contentRect.width)
    }

    const observer = new ResizeObserver(callback)
    observer.observe(wrapperRef.current, {})

    return () => {
      observer.disconnect()
    }
  }, [])

  return { wrapperRef, containerWidth }
}

interface Dimensions {
  width: number
  height: number
}

function getWindowDimensions(): Dimensions {
  if (typeof window === "undefined") {
    return {
      width: 0,
      height: 0,
    }
  }

  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function useGetWindowDimensions(): Dimensions | undefined {
  const [windowDimensions, setWindowDimensions] = useState<
    Dimensions | undefined
  >(undefined)

  useEffect(() => {
    setWindowDimensions(getWindowDimensions())

    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window?.addEventListener("resize", handleResize)
    return () => window?.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export const ScaleAndStretch: React.FC<
  WithClassName<{
    maxWidth: number
    heightToWidthRatio?: number
  }>
> = React.memo(({ children, maxWidth, heightToWidthRatio, className }) => {
  const maxWidthAttVal = useMemo(() => `${maxWidth}px`, [maxWidth])
  const dimensions = useGetWindowDimensions()
  const { containerWidth, wrapperRef } = useObserveContainerWidth()

  const shouldScale = dimensions && dimensions.width >= 640

  const height =
    heightToWidthRatio && shouldScale
      ? `${Math.round(containerWidth * heightToWidthRatio)}px`
      : undefined

  return (
    <>
      <div
        ref={wrapperRef}
        className="w-full"
        style={{
          maxWidth: maxWidthAttVal,
        }}
      />
      <div
        className={`relative w-full flex flex-row justify-center ${className}`}
        style={{
          maxWidth: maxWidthAttVal,
          height,
        }}
      >
        <div
          className="flex flex-col items-center origin-top-left static sm:absolute left-0"
          style={{
            width: shouldScale ? maxWidthAttVal : undefined,
            transform: shouldScale
              ? `scale(calc(${containerWidth}/${maxWidth}))`
              : undefined,
          }}
        >
          {children}
        </div>
      </div>
    </>
  )
})
