import React from "react"
import { Section } from "../Section"
import Gatsby from "../../images/svg/logos/gatsby.svg"
import Docker from "../../images/svg/logos/docker.svg"
import Next from "../../images/svg/logos/next.svg"
import Nuxt from "../../images/svg/logos/nuxt.svg"
import ReactLogo from "../../images/svg/logos/react-logo.svg"
import Svelte from "../../images/svg/logos/svelte.svg"
import Vite from "../../images/svg/logos/vite.svg"
import Vue from "../../images/svg/logos/vue.svg"
import Webpack from "../../images/svg/logos/webpack.svg"
import { ScaleAndStretch } from "./ScaleAndStretch"
import { Tooltip, Icon } from "@chakra-ui/react"

const ResponsiveLogo: React.FC<{ Logo: any; label: string }> = ({
  Logo,
  label,
}) => {
  return (
    <Tooltip label={label}>
      <span>
        <Logo className="w-12 h-12 sm:w-20 sm:h-20" />
      </span>
    </Tooltip>
  )
}
//clamp(0px, 50.8vw, 821px)
export const ConnectToProject: React.FC = () => {
  return (
    <Section>
      <ScaleAndStretch className="sm:h-connect-section" maxWidth={1140}>
        <h1 className="text-lc-main-blue text-4.6xl text-5 sm:text-5.5xl mb-12 sm:mb-22 mt-30 sm:mt-68 px-3 sm:px-0">
          Connect instantly to any project based on:
        </h1>
        <div className="grid grid-rows-3 sm:grid-rows-none grid-flow-col gap-10 sm:gap-12">
          <ResponsiveLogo label="Gatsby" Logo={Gatsby} />
          <ResponsiveLogo label="Docker" Logo={Docker} />
          <ResponsiveLogo label="Next.js" Logo={Next} />
          <ResponsiveLogo label="Nuxt" Logo={Nuxt} />
          <ResponsiveLogo label="React" Logo={ReactLogo} />
          <ResponsiveLogo label="Svelte" Logo={Svelte} />
          <ResponsiveLogo label="Vite" Logo={Vite} />
          <ResponsiveLogo label="Vue" Logo={Vue} />
          <ResponsiveLogo label="Webpack" Logo={Webpack} />
        </div>
        <h2 className="mt-14 mb-40 sm:mb-52 text-2xl sm:text-4.5xl">
          ...or any other Docker-based project
        </h2>
      </ScaleAndStretch>
    </Section>
  )
}
